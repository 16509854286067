var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px rgba(0, 0, 0, 0.05)",
      "border-radius": "md",
      "mt": "10px",
      "px": "20px",
      "w": ['100%', '100%']
    }
  }, [_c('c-flex', {
    attrs: {
      "h": "50px",
      "border-bottom": "1px solid #f4f4f4",
      "align-items": "center",
      "justify-content": "space-between",
      "px": "22px"
    }
  }, [_c('c-flex', [_c('c-text', {
    attrs: {
      "font-weight": "bold",
      "font-size": "14px",
      "line-height": "150%",
      "color": "#111111"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-text', {
    attrs: {
      "font-size": "14px",
      "line-height": "150%",
      "color": "#111111",
      "ml": "5px"
    }
  }, [_vm._v(" (" + _vm._s(_vm.level) + ") ")])], 1)], 1), _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mx": "0",
      "my": "15px"
    }
  }, [_c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100px",
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Ahli Gizi ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-width": "100px",
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333",
      "white-space": "nowrap",
      "text-overflow": "ellipsis",
      "overflow": "hidden"
    }
  }, [_vm._v(" " + _vm._s(_vm.getNutritionistName(_vm.ahliGizi)) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Durasi ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.duration) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Status ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Tanggal Mulai ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm._f("optional")(_vm.dateStart), "DD MMM YYYY")) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Tanggal Selesai ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm._f("optional")(_vm.dateEnd), "DD MMM YYYY")) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Harga ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("optional")(_vm.price)) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Kupon yang diterapkan ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("optional")(_vm.coupon)) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }