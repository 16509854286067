var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "my": "60px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": ['14px', '24px'],
      "line-height": ['21px', '36px'],
      "text-align": "center"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  }), _c('c-image', {
    attrs: {
      "mt": "20px",
      "h": ['189px', '300px'],
      "src": "https://ik.imagekit.io/dietela/pwa_webp/profile/undraw_empty_re_opql_2.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677059485965",
      "alt": _vm.imgalt
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }