<template>
  <c-box flex-grow="1">
    <c-flex
      flex-direction="column"
      :mx="[null, '0']"
      :mt="[null, '25px']"
      :mb="['80px', null]"
      w="100%"
    >
      <h1
        v-chakra
        font-weight="bold"
        font-size="18px"
        color="#111"
      >
        Riwayat Program
      </h1>

      <c-text
        font-weight="500"
        font-size="12px"
        color="#333"
        mt="25px"
      >
        Program yang pernah diikuti
      </c-text>
      <hr
        v-chakra
        mt="7px"
      >

      <EmptyCard
        v-if="isEmpty"
        label="Riwayat Program tidak tersedia"
      />

      <c-box v-if="!isEmpty">
        <ProgramCard
          v-for="item in programs"
          :key="item.id"
          :title="item.name"
          :level="item.programService"
          :ahli-gizi="item.nutritionist"
          :duration="item.duration"
          :status="item.status"
          :date-start="item.startAt"
          :date-end="item.endAt"
          :price="item.productPrice"
          :coupon="item.couponName"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProgramCard from './program-card.vue'
import EmptyCard from './empty.vue'

export default {
  name: 'ProfileProgramPage',
  components: {
    ProgramCard,
    EmptyCard,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      programs: 'profileProgram/history',
    }),
    isEmpty() {
      if (this.programs.length === 0) {
        return true
      }
      return false
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.listRiwayatPrograms()
      },
    },
  },
  mounted() {
    if (!this.isAuthenticated) return this.$router.push({ name: 'auth', query: { tab: 'login' } })
  },
  methods: {
    ...mapActions({
      listRiwayatPrograms: 'profileProgram/listHistoryPrograms',
    }),
  },
}
</script>
