var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "mx": [null, '0'],
      "mt": [null, '25px'],
      "mb": ['80px', null],
      "w": "100%"
    }
  }, [_c('h1', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111"
    }
  }, [_vm._v(" Riwayat Program ")]), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333",
      "mt": "25px"
    }
  }, [_vm._v(" Program yang pernah diikuti ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "7px"
    }
  }), _vm.isEmpty ? _c('EmptyCard', {
    attrs: {
      "label": "Riwayat Program tidak tersedia"
    }
  }) : _vm._e(), !_vm.isEmpty ? _c('c-box', _vm._l(_vm.programs, function (item) {
    return _c('ProgramCard', {
      key: item.id,
      attrs: {
        "title": item.name,
        "level": item.programService,
        "ahli-gizi": item.nutritionist,
        "duration": item.duration,
        "status": item.status,
        "date-start": item.startAt,
        "date-end": item.endAt,
        "price": item.productPrice,
        "coupon": item.couponName
      }
    });
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }