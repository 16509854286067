<template>
  <c-flex
    flex="1"
    flex-direction="column"
    justify-content="center"
    align-items="center"
    my="60px"
  >
    <c-text
      color="gray.900"
      :font-size="['14px', '24px']"
      :line-height="['21px', '36px']"
      text-align="center"
      v-html="label"
    />
    <c-image
      mt="20px"
      :h="['189px', '300px']"
      src="https://ik.imagekit.io/dietela/pwa_webp/profile/undraw_empty_re_opql_2.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677059485965"
      :alt="imgalt"
    />
  </c-flex>
</template>

<script>
export default {
  name: 'ProfileEmptyPage',
  props: {
    label: {
      type: String,
      default: '',
    },
    imgalt: {
      type: String,
      default: '',
    },
  },
}
</script>
